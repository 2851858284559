<template>
  <div class="relationshipauth">
    <mt-header :title="$t('relationshipauth')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="3" />
    <div class="content">
      <div v-for="(item, index) in form.contactExpansion" :key="index" class="box">
        <img v-if="index >= 2" class="delete" src="../../assets/delete.png" @click="form.contactExpansion.splice(index, 1)">
        <div v-if="Enum.contactRelation.map(e => e.code).indexOf(item.contactRelationCode) >= 0 || index == 0" class="separator">{{ $t('relativeContact') }}</div>
        <div v-else class="separator">{{ $t('otherContact') }}</div>
        <div>
          <mt-field
            :label="$t('relationship')"
            :placeholder="$t('relationshipPlaceholder')"
            readonly
            :state="index >= 2? null: contactRelationCodeState(item.contactRelationCode)"
            v-model="(relationshipAll.filter(e => e.code == item.contactRelationCode)[0]||{}).name"
            @click.native="onPopupPicker(index == 0? Enum.contactRelation: relationshipAll, (e) => {
              item.contactRelationCode = e.code;
              BEHAVIOR_ADD({
                id: `P03_C${FillZero((index * 3) + 1)}_S_RELATIONCODE`,
                newValue: item.contactRelationCode
              })
            })"
          >
            <template v-if="index == 0">
              <mt-spinner v-if="Enum.contactRelationLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
              <i v-else class="iconfont">&#xe62a;</i>
            </template>
            <template v-else>
              <mt-spinner v-if="Enum.contactRelationLoading && Enum.otherContactRelationLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
              <i v-else class="iconfont">&#xe62a;</i>
            </template>
          </mt-field>
          <mt-field
            :label="$t('name')"
            :placeholder="$t('namePlaceholder')"
            :state="index >= 2? null: contactNameState(item.contactName)"
            v-model="item.contactName"
            @blur.native.capture="BEHAVIOR_ADD({
              id: `P03_C${FillZero((index * 3) + 2)}_I_CONTACTNAME`,
              newValue: item.contactName
            })"
          ></mt-field>
          <mt-field
            :label="$t('phone')"
            :placeholder="$t('phonePlaceholder')"
            type="tel"
            v-mask="'#### #### #### #'"
            :state="index >= 2? null: maskedContactPhoneState(item.maskedContactPhone)"
            v-model="item.maskedContactPhone"
            @blur.native.capture="BEHAVIOR_ADD({
              id: `P03_C${FillZero((index * 3) + 3)}_I_CONTACTPHONE`,
              newValue: item.maskedContactPhone
            })"
          ></mt-field>
        </div>
      </div>
      <div class="add">
        <img
          src="../../assets/addContact.png"
          @click="form.contactExpansion.push({
            contactRelationCode: '',
            contactName: '',
            maskedContactPhone: '',
          })"
        >
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules, phoneRules, nameRules } from '../../utils/rules';
import { step } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
import getLocation from '../../utils/gps'
export default {
  name: 'Relationshipauth',
  mixins: [mixin],
  components: {
    popupPicker,
    callPhone,
    stepItem,
  },
  data() {
    return {
      isCheck: false,
      form: {
        contactExpansion: [
          {
            contactRelationCode: '', // 亲属联系人关系
            contactName: '', // 亲属联系人姓名
            maskedContactPhone: '', // 亲属联系人手机号
          }, {
            contactRelationCode: '', // 其他联系人关系
            contactName: '', // 其他联系人姓名
            maskedContactPhone: '', // 其他联系人手机号
          }
        ],
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    relationshipAll() {
      return this.Enum.contactRelation.concat(this.Enum.otherContactRelation)
    }
  },
  watch: {
    'form.contactExpansion': {
      handler() {
        this.onStorageItem('contactExpansion')
      },
      deep: true
    }
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      if (this.apply[val]) {
        this.form[val] = this.apply[val]
      }
    })
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P03_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P03_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_RELATIONSHIP(); // 亲属联系人
      this.GET_OTHER_RELATIONSHIP(); // 其他联系人
    },
    contactRelationCodeState(val) {
      return this.isCheck ? emptyRules(val) : null;
    },
    contactNameState(val) {
      return this.isCheck ? nameRules(val) : null;
    },
    maskedContactPhoneState(val) {
      if (this.isCheck && this.form.contactExpansion.filter(e => e.maskedContactPhone == val).length > 1) {
        return "warning"
      }
      return this.isCheck ? phoneRules(val) : null;
    },
    FillZero(p) {
      return new Array(2 - (p + '').length + 1).join('0') + p;
    },
    // 校验输入内容
    validation(list) {
      for (let i = 0; i < Math.min(this.form.contactExpansion.length, 2); i++) {
        if (list.indexOf('contactRelationCode') >= 0 && this.contactRelationCodeState(this.form.contactExpansion[i].contactRelationCode) != "success") {
          return this.$t('relationshipError')
        }
        if (list.indexOf('contactName') >= 0 && this.contactNameState(this.form.contactExpansion[i].contactName) != "success") {
          return this.contactNameState(this.form.contactExpansion[i].contactName) == 'warning'? this.$t('namePlaceholder'): this.$t('nameError')
        }
        if (list.indexOf('maskedContactPhone') >= 0 && this.maskedContactPhoneState(this.form.contactExpansion[i].maskedContactPhone) != "success") {
          return this.maskedContactPhoneState(this.form.contactExpansion[i].maskedContactPhone) == "warning"
            ? this.$t("phoneWarning")
            : this.$t("phoneError");
        }
      }
    },
    // 提交下一步
    async submit() {
      this.BEHAVIOR_ADD({
        id: 'P03_C98_B_SUBMIT'
      })
      this.isCheck = true;
      var error = this.validation(['contactRelationCode', 'contactName', 'maskedContactPhone']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('04_RELATIONSHIP_AUTH', { content_name: '04_RELATIONSHIP_AUTH' })
        }
        // Google Analytics  REALATIONSHIP_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'REALATIONSHIP_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        authName: "REATIONSHIP_AUTH",
        deviceId: "H5",
        source: "H5",
        channel: "H5",
        gps: await getLocation(),

        applyId: this.user.applyId,
        currentStep: 4,
        totalSteps: 7,
        contacts: this.form.contactExpansion.map(e => ({
          type: this.Enum.contactRelation.map(e => e.code).indexOf(e.contactRelationCode) >= 0? 'kin': 'friends',
          contactRelationCode: e.contactRelationCode,
          contactRelation: (this.relationshipAll.filter(item => item.code == e.contactRelationCode)[0]||{}).name,
          contactName: e.contactName,
          contactPhone: e.maskedContactPhone.replace(/\s/g, "")
        }))
      }
      await this.$axios({
        method: "post",
        url: step,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              name: e.body.name,
              applyId: e.body.applyId
            })
            this.$router.push('/step/bankInfo')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('04_RELATIONSHIP_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.relationshipauth {
  .content {
    margin: 0px 10px;
    border-radius: 10px;
    overflow: hidden;
    padding: 0px 10px;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.11);
    .mint-field {
      &:last-child {
        background-image: initial; 
      }
      &:first-child /deep/ .mint-cell-wrapper {
        background-image: initial; 
      }
    }
  }
  .box {
    position: relative;
    overflow: hidden;
  }
  .add {
    padding: 20px 10px 10px 10px;
    text-align: center;
    img {
      width: 25px;
    }
  }
  .delete {
    position: absolute;
    width: 25px;
    right: 15px;
    top: 15px;
    background-color: white;
  }
  .separator {
    border-color: #47b526;
    color: #27b526;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    margin: 16px 0;
    font-size: 14px;
    line-height: 24px;
    border-color: #ebedf0;
    border-style: solid;
    border-width: 0;
    &::before, &::after {
      content: '';
      display: block;
      flex: 1;
      box-sizing: border-box;
      height: 1px;
      border-color: inherit;
      border-style: inherit;
      border-width: 1px 0 0;
    }
    &::before {
      margin-right: 16px;
    }
    &::after {
      margin-left: 16px;
    }
  }
  .bottom {
    padding: 40px 20px 20px 20px;
  }
}
</style>